import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default () => {
  //simple fades
  gsap.utils.toArray('.fade-in').forEach((fader) => {
    gsap.to(fader, {
      ease: 'power1.Out',
      duration: 0.5,
      opacity: 1,
      delay: 0.1,
    });
  });

  gsap.utils.toArray('.fade-up').forEach((fadeUp) => {
    gsap.from(fadeUp, {
      ease: 'power1.Out',
      duration: 0.45,
      opacity: 0,
      y: 10,
      delay: 0.09,
    });
  });

  // Staggered fade-in-and-up
  gsap.set('.is-animated', {
    autoAlpha: 0,
    yPercent: 5,
  });

  ScrollTrigger.batch('.animate-contents', {
    onEnter: (batch) => {
      batch.forEach((section, i) => {
        gsap.to(section.querySelectorAll('.is-animated'), {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1.5,
          ease: 'power2.out',
          stagger: 0.1,
          delay: i * 0.05,
        });
      });
    },
    start: 'top 85%',
  });
};
