export default () => {
  const escapeButton = document.querySelector('#escape-button');

  function getAway() {
    window.open('http://weather.com', '_newtab'); //Open new tab and switch to it.
    window.location.replace('http://google.com'); //Change current tab
  }

  /* Trigger when escape key is pressed */
  escapeButton.addEventListener('click', () => {
    getAway();
  });

  // window.addEventListener('keydown', (event) => {
  //   if (event.key === 'Escape') {
  //     getAway();
  //   }
  // });
};
