import escapeButton from '../components/escape-button.js';
import flyoutMenu from '../components/flyout-menu.js';
import fitty from 'fitty';
import transitions from '../components/transitions.js';
import ARIAmodal from 'aria-modal-dialog';
// import MagicGrid from 'magic-grid';
import Colcade from 'colcade';

export default {
  init() {
    // JavaScript to be fired on all pages
    escapeButton();
    flyoutMenu();
    transitions();

    fitty('.phone-number__text', {
      minSize: 11,
      maxSize: 40,
    });

    ARIAmodal;

    //replace text in language switcher

    // Get all the elements.
    var elements = document.querySelectorAll(
      '.trp-language-switcher-container a'
    );
    // Iterate each.
    for (var i = 0; i < elements.length; i++) {
      // Check if the text is found.
      if (elements[i].innerHTML.indexOf('Español de México') != -1)
        // Replace the contents.
        elements[i].innerHTML = elements[i].innerHTML.replace(
          'Español de México',
          'En Español'
        );
    }

    //Colcade masonry for network partners page

    let colcade = new Colcade('.grid--2.network-cards', {
      columns: '.grid-col',
      items: '.grid-item',
    });

    colcade;

    //Cards

    const cards = document.querySelectorAll('.card');

    Array.prototype.forEach.call(cards, (card) => {
      // card.style.cursor = 'pointer';

      let down,
        up,
        link = card.querySelector('a');

      if (link) {
        card.style.cursor = 'pointer';
        card.classList.add('has-link');
      }
      card.onmousedown = () => (down = +new Date());
      card.onmouseup = () => {
        up = +new Date();
        if (up - down < 200) {
          link.click();
        }
      };
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
